// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue'; // 使用原始名称
import AgentManagement from '@/views/AgentManagement.vue';
import MerchantManagement from '@/views/MerchantManagement.vue';
import TiepaiManagement from '@/views/TiepaiManagement.vue';
import UserProfile from '@/views/UserProfile.vue';
import AboutUs from '@/views/AboutUs.vue';
import ContactUs from '@/views/ContactUs.vue';
import { auth } from '@/utils/auth.js'; // 引入 auth.js

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'Login', component: Login },
  {
    path: '/home',
    name: 'Home',
    component: Home, // 使用原始组件名称
    children: [
      {
        path: 'agent-management',
        name: 'AgentManagement',
        component: AgentManagement,
      },
      {
        path: 'merchant-management',
        name: 'MerchantManagement',
        component: MerchantManagement,
      },
      {
        path: 'tiepai-management',
        name: 'TiepaiManagement',
        component: TiepaiManagement,
      },
      {
        path: 'user-profile',
        name: 'UserProfile',
        component: UserProfile,
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        component: AboutUs, // 关于我们页面
      },
      {
        path: 'contact-us',
        name: 'ContactUs',
        component: ContactUs, // 联系我们页面
      },
    ],
    meta: { requiresAuth: true }, // 添加元信息，表示需要认证
  },
  // 其他路由，例如 404 页面
  { path: '/:pathMatch(.*)*', redirect: '/login' },
];

const router = createRouter({
  history: createWebHashHistory(), // 使用 Hash 模式
  routes,
});

// 添加全局前置守卫，检查是否需要认证
router.beforeEach((to, from, next) => {
  const isLoggedIn = !!auth.getStoredUser();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next('/login');
    } else {
      next();
    }
  } else {
    if (isLoggedIn && to.path === '/login') {
      next('/home/tiepai-management'); // 已登录用户访问登录页，重定向到默认页面
    } else {
      next();
    }
  }
});

export default router;
