<!-- src/components/Header.vue -->
<template>
  <el-header class="header">
    <div class="header-left">
      <h1>管理系统</h1>
    </div>
    <div class="header-right">
      <el-dropdown>
        <template #default>
          <div class="user-menu">
            <img src="https://www.shuzr.com/agent/images/icon.png" class="avatar" alt="头像" />
            <span class="username">{{ username }}</span>
            <el-icon>
              <ArrowDown />
            </el-icon>
          </div>
        </template>
        <template #dropdown>
          <el-dropdown-item @click="showChangePasswordModal">修改密码</el-dropdown-item>
          <el-dropdown-item divided @click="logout">退出登录</el-dropdown-item>
        </template>
      </el-dropdown>
    </div>

    <!-- 修改密码弹窗 -->
    <el-dialog title="修改密码" v-model="isChangePasswordVisible" width="400px" :before-close="handleClose">
      <el-form :model="passwordForm" :rules="rules" ref="passwordFormRef" label-width="100px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input type="password" v-model="passwordForm.oldPassword" autocomplete="current-password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="passwordForm.newPassword" autocomplete="new-password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input type="password" v-model="passwordForm.confirmPassword" autocomplete="new-password"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="isChangePasswordVisible = false">取消</el-button>
        <el-button type="primary" @click="submitChangePassword">确定</el-button>
      </template>
    </el-dialog>
  </el-header>
</template>

<script>
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { auth } from '@/utils/auth.js';
import axios from 'axios';
import config from '@/config.js'; // 引入配置文件

export default {
  name: 'AppHeader', // 使用多单词名称以符合 ESLint 规则
  components: {
    ...ElementPlusIconsVue,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isChangePasswordVisible: false,
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能少于6位', trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.passwordForm.newPassword) {
                callback(new Error('两次输入密码不一致'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    logout() {
      auth.clearUser();
      this.$router.push('/login');
    },
    showChangePasswordModal() {
      this.isChangePasswordVisible = true;
    },
    handleClose(done) {
      this.$refs.passwordFormRef.resetFields();
      done();
    },
    submitChangePassword() {
      this.$refs.passwordFormRef.validate(async (valid) => {
        if (valid) {
          try {
            const response = await axios.post(`${config.BASE_URL}/agent/api/agent_api.php`, {
              action: 'update_password',
              username: this.username,
              old_password: this.passwordForm.oldPassword,
              new_password: this.passwordForm.newPassword,
            });


            if (response.data.success) {
              this.$message({
                message: '密码修改成功，请重新登录',
                type: 'success',
              });
              auth.clearUser();
              this.$router.push('/login');
            } else {
              this.$message({
                message: response.data.message || '密码修改失败',
                type: 'error',
              });
            }
          } catch (error) {
            console.error(error);
            this.$message({
              message: '网络错误，请稍后再试',
              type: 'error',
            });
          } finally {
            this.isChangePasswordVisible = false;
            this.$refs.passwordFormRef.resetFields();
          }
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  border-bottom: 1px solid #eaeaea;
}

.header-left h1 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: #001529;
}

.header-right {
  display: flex;
  align-items: center;
}

.user-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.username {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.el-icon {
  font-size: 16px;
  color: #333;
}
</style>
