<template>
  <div class="agent-management">
    <el-card class="mb-4" shadow="never">
      <!-- 查询条件 -->
      <el-form :model="searchForm" inline>
        <el-form-item label="代理名称">
          <el-input placeholder="请输入代理名称" v-model="searchForm.agent_name"></el-input>
        </el-form-item>
        <el-form-item label="代理账号">
          <el-input placeholder="请输入代理账号" v-model="searchForm.username"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="mb-4" shadow="never">
      <div class="toolbar">
        <el-button type="primary" @click="showAddModal">新增</el-button>
      </div>
      <!-- 数据表格 -->
      <el-table :data="tableData" style="width: 100%" v-loading="loading" border>
        <el-table-column prop="id" label="代理ID" width="80" />
        <el-table-column prop="agent_name" label="代理名称" />
        <!-- 上级代理和代理等级不展示 -->
        <el-table-column prop="store_count" label="商户数量" />
        <!-- 代理数量不展示 -->
        <el-table-column prop="username" label="代理账号" />
        <el-table-column prop="local_computing" label="本地算力" />
        <el-table-column prop="cloud_computing" label="云端算力" />
        <el-table-column prop="computing_power_status" label="算力机" :formatter="computingPowerStatusFormatter" />
        <el-table-column prop="computing_power" label="算力机数" :formatter="computingPowerFormatter" />
        <el-table-column prop="is_open" label="是否禁用" :formatter="is_openFormatter" />
        <el-table-column prop="out_date" label="过期时间" :formatter="dateFormatter" />
        <el-table-column prop="addtime" label="添加时间" :formatter="datetimeFormatter" />
        <el-table-column prop="uptime" label="更新时间" :formatter="datetimeFormatter" />
        <el-table-column label="操作" width="200">
          <template #default="{ row }">
            <el-link type="primary" @click="showEditModal(row)">编辑</el-link>
            <el-link type="success" style="margin-left:8px;" @click="showRechargeModal(row)">充值</el-link>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-wrap">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="pagination.currentPage" :page-sizes="[15, 30, 50]" :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增/编辑对话框 -->
    <el-dialog :title="isEdit ? '编辑代理' : '新增代理'" v-model="dialogVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="formRef" label-width="120px">
        <el-form-item label="代理名称" prop="agent_name">
          <el-input v-model="form.agent_name"></el-input>
        </el-form-item>
        <!-- agent_pid 不显示 -->
        <!-- level 不显示 -->
        <el-form-item label="商户数量" prop="store_count">
          <el-input-number v-model="form.store_count" :min="0" :disabled="isEdit"></el-input-number>
        </el-form-item>
        <!-- agent_count 不显示 -->
        <el-form-item label="代理账号" prop="username">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="代理密码" prop="password">
          <el-input type="password" v-model="form.password" :placeholder="isEdit ? '不填写则不修改密码' : '请输入密码'"></el-input>
        </el-form-item>

        <el-form-item label="本地算力" prop="local_computing">
          <el-input-number v-model="form.local_computing" :min="0" :disabled="isEdit"></el-input-number>
        </el-form-item>
        <el-form-item label="云端算力" prop="cloud_computing">
          <el-input-number v-model="form.cloud_computing" :min="0" :disabled="isEdit"></el-input-number>
        </el-form-item>

        <el-form-item label="算力机" prop="computing_power_status">
          <el-radio-group v-model="form.computing_power_status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="算力机数" prop="computing_power">
          <el-input-number v-model="form.computing_power" :min="0" :disabled="isEdit"></el-input-number>
        </el-form-item>
        <el-form-item label="是否禁用" prop="is_open">
          <el-radio-group v-model="form.is_open">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="过期时间" prop="out_date">
          <el-date-picker v-model="form.out_date" type="date" placeholder="选择日期" format="YYYY-MM-DD"
            value-format="YYYY-MM-DD">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm">确定</el-button>
      </template>
    </el-dialog>

    <!-- 充值弹窗 -->
    <el-dialog title="充值" v-model="dialogRechargeVisible" width="500px">
      <!-- 新增的警告信息 -->
      <div style="color: red; font-size: 10px; font-weight: bold; margin-bottom: 10px;margin-left: 22px;">
        充值成功后，无法撤回，请慎重充值！
      </div>
      <el-form :model="rechargeForm" :rules="rechargeRules" ref="rechargeFormRef" label-width="100px">
        <el-form-item label="充值类型" prop="recharge_type">
          <el-select v-model="rechargeForm.recharge_type" placeholder="请选择充值类型">
            <el-option label="商户数量" value="store_count"></el-option>
            <el-option label="本地算力" value="local_computing"></el-option>
            <el-option label="云端算力" value="cloud_computing"></el-option>
            <!-- 新增选项：算力机数 -->
            <el-option label="算力机数" value="computing_power"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值数量" prop="amount">
          <el-input-number v-model="rechargeForm.amount" :min="1"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogRechargeVisible = false">取消</el-button>
        <el-button type="primary" @click="submitRecharge">充值</el-button>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue';
import { ElMessage } from 'element-plus';
import axios from 'axios';
import config from '@/config.js';
import dayjs from 'dayjs';
import { auth } from '@/utils/auth.js';

export default {
  name: 'AgentManagement',
  setup() {
    const searchForm = reactive({
      agent_name: '',
      username: '',
    });

    const tableData = ref([]);
    const loading = ref(false);

    const pagination = reactive({
      currentPage: 1,
      pageSize: 15,
      total: 0,
    });

    const dialogVisible = ref(false);
    const isEdit = ref(false);
    const formRef = ref(null);

    const dialogRechargeVisible = ref(false);
    const rechargeFormRef = ref(null);
    const selectedUserId = ref(null);

    const form = reactive({
      id: '', // 添加 id 字段
      agent_name: '',
      store_count: 0,
      username: '',
      password: '',
      local_computing: 0,
      cloud_computing: 0,
      computing_power_status: 0,
      computing_power: 0,
      is_open: 0,
      out_date: '',
      addtime: '',
      uptime: '',
    });

    // 定义计算属性 rules，使其根据 isEdit 动态变化
    const rules = computed(() => ({
      agent_name: [
        { required: true, message: '请输入代理名称', trigger: 'blur' }
      ],
      store_count: [
        { required: true, message: '请输入商户数量', trigger: 'blur' }
      ],
      username: [
        { required: true, message: '请输入代理账号', trigger: 'blur' },
        { min: 6, message: '代理账号至少6位', trigger: 'blur' }
      ],
      password: [
        { required: !isEdit.value, message: '请输入代理密码', trigger: 'blur' },
        { min: 6, message: '代理密码至少6位', trigger: 'blur' }
      ],
      local_computing: [
        { required: true, message: '请输入本地算力', trigger: 'blur' }
      ],
      cloud_computing: [
        { required: true, message: '请输入云端算力', trigger: 'blur' }
      ],
      computing_power_status: [
        { required: true, message: '请选择是否开启', trigger: 'change' }
      ],
      computing_power: [
        { required: true, message: '请输入算力机数', trigger: 'blur' }
      ],
      is_open: [
        { required: true, message: '请选择是否禁用', trigger: 'change' }
      ],
      out_date: [
        { required: true, message: '请选择过期时间', trigger: 'change' }
      ],
    }));


    const rechargeForm = reactive({
      recharge_type: '',
      amount: ''
    });

    const rechargeRules = {
      recharge_type: [{ required: true, message: '请选择充值类型', trigger: 'blur' }],
      amount: [
        { required: true, message: '请输入充值数量', trigger: 'blur' },
        { type: 'number', message: '数量必须是数字', trigger: 'blur' }
      ],
    };

    const user = auth.getStoredUser();
    if (!user) {
      ElMessage.error('未检测到登录用户信息，请重新登录');
      // 根据实际需求跳转登录页
    }

    const handleQuery = () => {
      pagination.currentPage = 1;
      fetchData();
    };

    const handleReset = () => {
      searchForm.agent_name = '';
      searchForm.username = '';
      pagination.currentPage = 1;
      fetchData();
    };

    const fetchData = async () => {
      loading.value = true;
      try {
        const response = await axios.post(`${config.BASE_URL}/agent/api/agents_api.php`, {
          action: 'query',
          operator_username: user.username, // 使用 operator_username 传递操作员用户名
          agent_name: searchForm.agent_name,
          username_search: searchForm.username,
          page: pagination.currentPage,
          pageSize: pagination.pageSize,
        });

        if (response.data.success) {
          tableData.value = response.data.data || [];
          pagination.total = response.data.total || 0;
        } else {
          ElMessage.error(response.data.message || '查询失败');
        }
      } catch (error) {
        console.error('查询失败:', error);
        ElMessage.error('查询失败，请检查网络或后端接口');
      } finally {
        loading.value = false;
      }
    };

    const handleSizeChange = (size) => {
      pagination.pageSize = size;
      fetchData();
    };

    const handleCurrentChange = (page) => {
      pagination.currentPage = page;
      fetchData();
    };

    const showAddModal = () => {
      isEdit.value = false;
      resetForm();
      dialogVisible.value = true;
    };

    const showEditModal = (row) => {
      isEdit.value = true;
      resetForm();
      Object.assign(form, row);
      form.password = ''; // 确保密码字段为空
      dialogVisible.value = true;
    };

    const showRechargeModal = (row) => {
      selectedUserId.value = row.id;
      rechargeForm.recharge_type = '';
      rechargeForm.amount = '';
      dialogRechargeVisible.value = true;
    };

    const resetForm = () => {
      form.id = '';
      form.agent_name = '';
      // agent_pid不展示，不传递，level默认2在新增时就设好无需展示，agent_count保持0
      form.store_count = 0;
      form.username = '';
      form.password = '';
      form.local_computing = 0;
      form.cloud_computing = 0;
      form.computing_power_status = 0;
      form.computing_power = 0;
      form.is_open = 0;
      form.out_date = '';
      form.addtime = '';
      form.uptime = '';
    };

    // 提交新增/编辑表单
    const submitForm = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          let action = isEdit.value ? 'update' : 'add';
          // 构建提交数据
          let payload = {
            action,
            operator_username: user.username, // 当前操作员的用户名
            agent_name: form.agent_name,
            store_count: form.store_count,
            username: form.username,
            local_computing: form.local_computing,
            cloud_computing: form.cloud_computing,
            computing_power_status: form.computing_power_status,
            computing_power: form.computing_power,
            is_open: Number(form.is_open), // 确保为数字
            out_date: form.out_date,
          };

          if (isEdit.value) {
            payload.id = form.id; // 编辑时需要 ID
            if (form.password) {
              payload.password = form.password; // 仅在有密码时才添加
            }
          } else {
            payload.password = form.password; // 新增时必须添加密码
          }

          try {
            const response = await axios.post(`${config.BASE_URL}/agent/api/agents_api.php`, payload);
            if (response.data.success) {
              ElMessage.success(isEdit.value ? '编辑成功' : '新增成功');
              dialogVisible.value = false;
              fetchData();
            } else {
              ElMessage.error(response.data.message || (isEdit.value ? '编辑失败' : '新增失败'));
            }
          } catch (error) {
            console.error('提交失败:', error);
            ElMessage.error(isEdit.value ? '编辑失败' : '新增失败');
          }
        }
      });
    };

    // 提交充值表单
    const submitRecharge = () => {
      rechargeFormRef.value.validate(async (valid) => {
        if (valid) {
          try {
            const response = await axios.post(`${config.BASE_URL}/agent/api/agents_api.php`, {
              action: 'recharge',
              operator_username: user.username, // 使用 operator_username 传递操作员用户名
              user_id: selectedUserId.value,
              recharge_type: rechargeForm.recharge_type,
              amount: Number(rechargeForm.amount),
            });

            if (response.data.success) {
              ElMessage.success('充值成功');
              dialogRechargeVisible.value = false;
              fetchData(); // 充值成功后刷新数据以显示更新后的算力
            } else {
              ElMessage.error(response.data.message || '充值失败');
            }
          } catch (error) {
            console.error('充值失败:', error);
            ElMessage.error('充值失败');
          }
        }
      });
    };

    const dateFormatter = (row, column, cellValue) => {
      if (!cellValue) return '';
      return dayjs(cellValue).format('YYYY-MM-DD');
    };

    const datetimeFormatter = (row, column, cellValue) => {
      if (!cellValue) return '';
      return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss');
    };

    const computingPowerFormatter = (row) => {
      return row.computing_power;
    };

    const computingPowerStatusFormatter = (row) => {
      // 确保状态值是数字类型
      const status = Number(row.computing_power_status);
      return status === 1 ? '正常' : '禁用';
    };


    const is_openFormatter = (row) => {
      return row.is_open === 0 ? '正常' : '禁用';
    };

    onMounted(() => {
      fetchData();
    });

    return {
      searchForm,
      tableData,
      loading,
      pagination,
      dialogVisible,
      isEdit,
      form,
      rules, // 现在是计算属性
      formRef,
      showAddModal,
      showEditModal,
      resetForm,
      submitForm,
      handleQuery,
      handleReset,
      handleSizeChange,
      handleCurrentChange,
      dialogRechargeVisible,
      rechargeForm,
      rechargeRules,
      rechargeFormRef,
      showRechargeModal,
      submitRecharge,
      dateFormatter,
      datetimeFormatter,
      computingPowerFormatter,
      computingPowerStatusFormatter,
      is_openFormatter,
    };
  },
};
</script>

<style scoped>
.agent-management {
  padding: 1px;
}

.mb-4 {
  margin-bottom: 20px;
}

.toolbar {
  margin-bottom: 10px;
}

.pagination-wrap {
  margin-top: 10px;
  text-align: right;
}

.section-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
