<!-- src/components/Sidebar.vue -->
<template>
  <el-aside :width="isCollapsed ? '64px' : '240px'" class="aside">
    <div class="logo">
      <icon-house />
      <span v-if="!isCollapsed" class="logo-text">数字人代理端</span>
    </div>
    <el-menu :default-active="activeMenu" class="menu" @select="handleMenuSelect" :collapse="isCollapsed"
      collapse-transition :unique-opened="true" router>
      <!-- 代理管理 (仅当 agentLevel !== 2 时显示) -->
      <el-menu-item v-if="agentLevel !== 2" index="AgentManagement">
        <el-icon>
          <Platform />
        </el-icon>
        <span v-if="!isCollapsed">代理管理</span>
      </el-menu-item>

      <!-- 商户管理 -->
      <el-menu-item index="MerchantManagement">
        <el-icon>
          <Shop />
        </el-icon>
        <span v-if="!isCollapsed">商户管理</span>
      </el-menu-item>

      <!-- 贴牌管理 (仅当 agentLevel !== 2 时显示) -->
      <el-menu-item v-if="agentLevel !== 2" index="TiepaiManagement">
        <el-icon>
          <House />
        </el-icon>
        <span v-if="!isCollapsed">贴牌管理</span>
      </el-menu-item>
      <el-menu-item v-if="agentLevel !== 2"  index="AboutUs">
        <el-icon>
          <InfoFilled />
        </el-icon>
        <span v-if="!isCollapsed">关于我们</span>
      </el-menu-item>
      <el-menu-item v-if="agentLevel !== 2"  index="ContactUs">
        <el-icon>
          <PhoneFilled />
        </el-icon>
        <span v-if="!isCollapsed">联系我们</span>
      </el-menu-item>
      <!-- 个人信息 -->
      <el-menu-item index="UserProfile">
        <el-icon>
          <User />
        </el-icon>
        <span v-if="!isCollapsed">个人信息</span>
      </el-menu-item>
    </el-menu>
    <!-- 折叠按钮 -->
    <div class="toggle-button" @click="toggleAside">
      <el-icon>
        <Fold v-if="!isCollapsed" />
        <Expand v-else />
      </el-icon>
    </div>
  </el-aside>
</template>

<script>
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { auth } from '@/utils/auth.js';
import config from '@/config.js'; // 引入配置文件

export default {
  name: 'AppSidebar', // 使用多单词名称以符合 ESLint 规则
  components: {
    ...ElementPlusIconsVue,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
    activeMenu: {
      type: String,
      required: true,
    },
  },
  emits: ['toggleAside', 'menuSelect'],
  setup(props, { emit }) {
    const router = useRouter();
    const agentLevel = ref(null); // 存储代理等级

    // 方法：获取代理信息
    const fetchAgentInfo = async () => {
      try {
        const user = auth.getStoredUser();
        if (!user) {
          ElMessage.error('未登录，请重新登录');
          router.push('/login');
          return;
        }

        const username = user.username;

        // 发送请求获取代理信息
        const response = await axios.post(
          `${config.BASE_URL}/agent/api/agent_api.php`,
          { action: 'query', username: username }
        );

        if (response.data.success) {
          const data = response.data.data;
          // 根据 level 设置 agentLevel
          agentLevel.value = parseInt(data.level, 10) || 0;
        } else {
          ElMessage.error(response.data.message || '查询代理信息失败');
        }
      } catch (error) {
        console.error('查询代理信息失败:', error);
        ElMessage.error('查询代理信息失败，请检查网络连接');
      }
    };

    // 在组件挂载时调用 fetchAgentInfo 方法
    onMounted(() => {
      fetchAgentInfo();
    });

    // 处理菜单选择
    const handleMenuSelect = (index) => {
      emit('menuSelect', index);
      router.push({ name: index });
    };

    // 处理侧边栏折叠
    const toggleAside = () => {
      emit('toggleAside');
    };

    return {
      agentLevel,
      handleMenuSelect,
      toggleAside,
    };
  },
};
</script>

<style scoped>
.aside {
  color: #fff;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.logo {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.logo .logo-text {
  margin-left: 3px;
  opacity: 1;
  transition: all 0.3s ease;
  color: #000;
  font-size: 25px;
}

.menu {
  margin-top: 20px;
}

.menu .el-menu-item {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.menu .el-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #409eff;
}

.menu .el-menu-item.is-active {
  background-color: rgba(64, 158, 255, 0.1);
  color: #409eff;
  font-weight: bold;
}

.menu .el-icon {
  font-size: 18px;
  margin-right: 12px;
  color: inherit;
}

.toggle-button {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
  transition: transform 0.3s ease, color 0.3s ease;
}

.toggle-button:hover {
  transform: scale(1.1);
  color: #409eff;
}
</style>
