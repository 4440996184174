<template>
    <div class="contact-us">
      <div class="section">
        <h2 class="section-title">联系我们</h2>
        <el-form :model="contactForm" label-width="120px">
          <el-form-item label="姓名">
            <el-input v-model="contactForm.name" placeholder="请输入联系人姓名" />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="contactForm.phone" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item label="微信号">
            <el-input v-model="contactForm.wechat" placeholder="请输入微信号" />
          </el-form-item>
          <el-form-item label="QQ号">
            <el-input v-model="contactForm.qq" placeholder="请输入QQ号" />
          </el-form-item>
          <el-form-item label="二维码">
            <el-input v-model="contactForm.qrcode_url" placeholder="请输入二维码图片URL" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveContactInfo">保存修改</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import config from '@/config.js';
  
  export default {
    name: 'ContactUs',
    data() {
      return {
        contactForm: {
          name: '',
          phone: '',
          wechat: '',
          qq: '',
          qrcode_url: '',
          host_url: config.BASE_URL, // 默认值为 config.BASE_URL
        },
      };
    },
    methods: {
      /**
       * 获取联系我们信息
       */
      async fetchContactInfo() {
        try {
          const response = await axios.post(`${config.BASE_URL}/agent/api/contact_api.php`, {
            action: 'get',
            host_url: this.contactForm.host_url, // 传递 host_url 参数
          });
          if (response.data.success) {
            this.contactForm = {
              ...response.data.data,
              host_url: response.data.data.host_url || config.BASE_URL, // 确保 host_url 存在
            };
          } else {
            this.$message.error(response.data.message || '获取联系我们信息失败');
          }
        } catch (error) {
          console.error('获取联系我们信息失败:', error);
          this.$message.error('网络错误，请稍后重试');
        }
      },
      /**
       * 保存联系我们信息
       */
      async saveContactInfo() {
        try {
          const response = await axios.post(`${config.BASE_URL}/agent/api/contact_api.php`, {
            action: 'save',
            host_url: this.contactForm.host_url || config.BASE_URL, // 确保 host_url 传递
            ...this.contactForm,
          });
          if (response.data.success) {
            this.$message.success('联系我们信息保存成功');
          } else {
            this.$message.error(response.data.message || '保存失败');
          }
        } catch (error) {
          console.error('保存联系我们信息失败:', error);
          this.$message.error('网络错误，请稍后重试');
        }
      },
    },
    created() {
      this.fetchContactInfo();
    },
  };
  </script>
  
  <style scoped>
  .section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  .section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  </style>
  