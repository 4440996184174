import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import router from './router';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';

const app = createApp(App);

// 注册图标组件为全局组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 使用 Element Plus 并设置中文语言环境，只需要调用一次
app.use(ElementPlus, { locale: zhCn });

// 注册路由
app.use(router);

app.mount('#app');
