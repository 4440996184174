<template>
  <div class="merchant-management">
    <el-card class="mb-4" shadow="never">
      <!-- 查询条件 -->
      <el-form :model="searchForm" inline>
        <el-form-item label="用户昵称">
          <el-input placeholder="请输入用户昵称" v-model="searchForm.nickName"></el-input>
        </el-form-item>
        <el-form-item label="用户账号">
          <el-input placeholder="请输入用户账号" v-model="searchForm.account"></el-input>
        </el-form-item>
        <el-form-item label="用户电话">
          <el-input placeholder="请输入用户电话" v-model="searchForm.mobile"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="mb-4" shadow="never">
      <div class="toolbar">
        <el-button type="primary" @click="showAddModal">新增</el-button>
      </div>
      <!-- 数据表格 -->
      <el-table :data="tableData" style="width: 100%" v-loading="loading" border>
        <el-table-column prop="id" label="用户ID" width="80" />

        <el-table-column prop="nickName" label="用户昵称" />
        <el-table-column prop="account" label="用户账号" />
        <el-table-column prop="mobile" label="用户电话" />
        <el-table-column prop="local_computing" label="本地算力" />
        <el-table-column prop="cloud_computing" label="云端算力" />
        <el-table-column prop="computing_power" label="算力机" :formatter="computingPowerFormatter" />
        <el-table-column prop="out_date" label="过期时间" :formatter="dateFormatter" />
        <el-table-column prop="is_disable" label="是否禁用" :formatter="isDisableFormatter" />
        <el-table-column prop="create_time" label="创建时间" :formatter="unixDateFormatter" />
        <el-table-column label="操作" width="150">
          <template #default="{ row }">
            <el-link type="primary" @click="showEditModal(row)">编辑</el-link>
            <el-link type="success" style="margin-left:8px;" @click="showRechargeModal(row)">充值</el-link>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="pagination-wrap">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="pagination.currentPage" :page-sizes="[15, 30, 50]" :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增/编辑对话框 -->
    <el-dialog title="商户信息" v-model="dialogVisible" width="500px">
      <el-form :model="form" :rules="rules" ref="formRef" label-width="120px">
        <el-form-item label="用户昵称" prop="nickName">
          <el-input v-model="form.nickName"></el-input>
        </el-form-item>
        <el-form-item label="用户账号" prop="account">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="用户密码" prop="password">
          <el-input type="password" v-model="form.password" :placeholder="isEdit ? '不填写则不修改密码' : '请输入密码'"></el-input>
        </el-form-item>
        <el-form-item label="用户电话" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="算力机" prop="computing_power">
          <el-radio-group v-model="form.computing_power">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="本地算力" prop="local_computing">
          <!-- 编辑时禁用输入 -->
          <el-input v-model="form.local_computing" :disabled="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="云端算力" prop="cloud_computing">
          <!-- 编辑时禁用输入 -->
          <el-input v-model="form.cloud_computing" :disabled="isEdit"></el-input>
          <!-- 新增时显示提示信息 -->
          <div v-if="!isEdit" style="color:red; font-weight:bold; margin-top:5px; font-size:12px;">
            如果需要使用云端算力，请关闭本地算力，设置为 -1 即为关闭。
          </div>

        </el-form-item>
        <el-form-item label="过期时间" prop="out_date">
          <el-date-picker v-model="form.out_date" type="date" placeholder="选择日期" format="YYYY-MM-DD"
            value-format="YYYY-MM-DD">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否禁用" prop="is_disable">
          <el-radio-group v-model="form.is_disable">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm" v-if="!isEdit">确定</el-button>
        <!-- 如果是编辑状态，可以根据需求选择是否隐藏确定按钮或者保留 -->
        <el-button type="primary" @click="submitForm" v-if="isEdit">确定</el-button>
      </template>
    </el-dialog>

    <!-- 充值弹窗 -->
    <el-dialog title="充值" v-model="dialogRechargeVisible" width="400px">
            <!-- 新增的警告信息 -->
            <div style="color: red; font-size: 10px; font-weight: bold; margin-bottom: 10px;margin-left: 52px;">
        充值成功后，无法撤回，请慎重充值！
      </div>
      <el-form :model="rechargeForm" :rules="rechargeRules" ref="rechargeFormRef" label-width="100px">
        <el-form-item label="类型" prop="type">
          <el-select v-model="rechargeForm.type" placeholder="请选择类型">
            <el-option label="本地算力" value="local"></el-option>
            <el-option label="云端算力" value="cloud"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量" prop="amount">
          <el-input-number v-model="rechargeForm.amount" :min="1"></el-input-number>
        </el-form-item>

      </el-form>
      <template #footer>
        <el-button @click="dialogRechargeVisible = false">取消</el-button>
        <el-button type="primary" @click="submitRecharge">充值</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue';
import { ElMessage } from 'element-plus';
import axios from 'axios';
import config from '@/config.js';
import dayjs from 'dayjs';
import { auth } from '@/utils/auth.js';

export default {
  name: 'MerchantManagement',
  setup() {
    const searchForm = reactive({
      nickName: '',
      account: '',
      mobile: '',
    });

    const tableData = ref([]);
    const loading = ref(false);
    const pagination = reactive({
      currentPage: 1,
      pageSize: 15,
      total: 0,
    });

    const dialogVisible = ref(false);
    const isEdit = ref(false);
    const formRef = ref(null);

    const dialogRechargeVisible = ref(false);
    const rechargeFormRef = ref(null);
    const selectedUserId = ref(null); // 用于存储当前选择的商户ID

    const form = reactive({
      id: '',
      nickName: '',
      account: '',
      password: '',
      mobile: '',
      application_market: {
        "voice_clone": true,
        "animal_clone": true,
        "person_clone": true,
        "video_editing": true,
        "activation_code": true,
        "sculpture_clone": true,
        "xhs_content_sharing": true,
        "douyin_content_sharing": true,
        "bilibili_content_sharing": true,
        "kuaishou_content_sharing": true
      },
      computing_power: 0,
      local_computing: '',
      cloud_computing: '',
      out_date: '',
      is_disable: 0,
    });

    const rules = computed(() => ({
      nickName: [
        { required: true, message: '请输入用户昵称', trigger: 'blur' }
      ],
      account: [
        { required: true, message: '请输入用户账号', trigger: 'blur' },
        { min: 6, message: '账号至少6位', trigger: 'blur' }
      ],
      password: [
        { required: !isEdit.value, message: '请输入代理密码', trigger: 'blur' },
        { min: 6, message: '密码至少6位', trigger: 'blur' }
      ],
      mobile: [
        { required: true, message: '请输入用户电话', trigger: 'blur' },
        {
          pattern: /^\d{11}$/,
          message: '电话必须为11位数字',
          trigger: 'blur'
        }
      ],
      local_computing: [
        { required: true, message: '请输入本地算力', trigger: 'blur' }
      ],
      cloud_computing: [
        { required: true, message: '请输入云端算力', trigger: 'blur' }
      ],
      out_date: [
        { required: true, message: '请选择过期时间', trigger: 'change' }
      ],
      is_disable: [
        { required: true, message: '请选择是否禁用', trigger: 'change' }
      ],
      computing_power: [
        { required: true, message: '请选择算力机状态', trigger: 'change' }
      ],
    }));


    const rechargeForm = reactive({
      type: '',
      amount: ''
    });

    const rechargeRules = {
      type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
      amount: [
        { required: true, message: '请输入数量', trigger: 'blur' },
        { type: 'number', message: '数量必须是数字', trigger: 'blur' }
      ],
    };

    const user = auth.getStoredUser();
    if (!user) {
      ElMessage.error('未检测到登录用户信息，请重新登录');
      // 根据需要跳转登录页
    }

    const handleQuery = () => {
      pagination.currentPage = 1;
      fetchData();
    };

    const handleReset = () => {
      searchForm.nickName = '';
      searchForm.account = '';
      searchForm.mobile = '';
      pagination.currentPage = 1;
      fetchData();
    };

    const fetchData = async () => {
      loading.value = true;
      try {
        const response = await axios.post(`${config.BASE_URL}/agent/api/merchant_api.php`, {
          action: 'query',
          username: user.username,
          nickName: searchForm.nickName,
          account: searchForm.account,
          mobile: searchForm.mobile,
          page: pagination.currentPage,
          pageSize: pagination.pageSize,
        });

        if (response.data.success) {
          tableData.value = response.data.data || [];
          pagination.total = response.data.total || 0;
        } else {
          ElMessage.error(response.data.message || '查询失败');
        }
      } catch (error) {
        console.error(error);
        ElMessage.error('查询失败，请检查网络或后端接口');
      } finally {
        loading.value = false;
      }
    };

    const handleSizeChange = (size) => {
      pagination.pageSize = size;
      fetchData();
    };

    const handleCurrentChange = (page) => {
      pagination.currentPage = page;
      fetchData();
    };

    const showAddModal = () => {
      isEdit.value = false;
      resetForm();
      dialogVisible.value = true;
    };

    const showEditModal = (row) => {
      isEdit.value = true;
      resetForm();
      Object.assign(form, row);
      dialogVisible.value = true;
    };

    const showRechargeModal = (row) => {
      selectedUserId.value = row.id;
      rechargeForm.type = '';
      rechargeForm.amount = '';
      dialogRechargeVisible.value = true;
    };

    const resetForm = () => {
      form.id = '';
      form.nickName = '';
      form.account = '';
      form.password = '';
      form.mobile = '';
      form.local_computing = '';
      form.cloud_computing = '';
      form.out_date = '';
      form.is_disable = 0;
      form.computing_power = 0;
    };

    const submitForm = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          let action = isEdit.value ? 'update' : 'add';
          let payload = { action, username: user.username, ...form };

          if (isEdit.value && !form.id) {
            ElMessage.error('缺少要编辑的ID');
            return;
          }

          try {
            const response = await axios.post(`${config.BASE_URL}/agent/api/merchant_api.php`, payload);
            if (response.data.success) {
              ElMessage.success(isEdit.value ? '编辑成功' : '新增成功');
              dialogVisible.value = false;
              fetchData();
            } else {
              ElMessage.error(response.data.message || (isEdit.value ? '编辑失败' : '新增失败'));
            }
          } catch (error) {
            console.error(error);
            ElMessage.error(isEdit.value ? '编辑失败' : '新增失败');
          }
        }
      });
    };

    const submitRecharge = () => {
      rechargeFormRef.value.validate(async (valid) => {
        if (valid) {
          try {
            const response = await axios.post(`${config.BASE_URL}/agent/api/merchant_api.php`, {
              action: 'recharge',
              username: user.username,
              user_id: selectedUserId.value,
              type: rechargeForm.type,
              amount: Number(rechargeForm.amount)
            });

            if (response.data.success) {
              ElMessage.success('充值成功');
              dialogRechargeVisible.value = false;
              fetchData(); // 充值成功后刷新数据以显示更新后的算力
            } else {
              ElMessage.error(response.data.message || '充值失败');
            }
          } catch (error) {
            console.error(error);
            ElMessage.error('充值失败');
          }
        }
      });
    };

    const dateFormatter = (row, column, cellValue) => {
      if (!cellValue) return '';
      return dayjs(cellValue).format('YYYY-MM-DD');
    };

    const unixDateFormatter = (row, column, cellValue) => {
      if (!cellValue) return '';
      return dayjs.unix(cellValue).format('YYYY-MM-DD HH:mm:ss');
    };

    const computingPowerFormatter = (row) => {
      return row.computing_power === 1 ? '开启' : '关闭';
    };

    const isDisableFormatter = (row) => {
      return row.is_disable === 1 ? '禁用' : '正常';
    };

    onMounted(() => {
      fetchData();
    });

    return {
      searchForm,
      tableData,
      loading,
      pagination,
      dialogVisible,
      form,
      rules,
      formRef,
      isEdit,
      dialogRechargeVisible,
      rechargeForm,
      rechargeRules,
      rechargeFormRef,
      selectedUserId,
      handleQuery,
      handleReset,
      handleSizeChange,
      handleCurrentChange,
      showAddModal,
      showEditModal,
      showRechargeModal,
      submitForm,
      submitRecharge,
      computingPowerFormatter,
      isDisableFormatter,
      dateFormatter,
      unixDateFormatter
    };
  },
};
</script>

<style scoped>
.merchant-management {
  padding: 1px;
}

.mb-4 {
  margin-bottom: 20px;
}

.toolbar {
  margin-bottom: 10px;
}

.pagination-wrap {
  margin-top: 10px;
  text-align: right;
}
</style>
