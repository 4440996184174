

export const auth = {
  /**
   * 检查存储中的账号信息（localStorage 或 sessionStorage）
   * @returns {Object|null} 返回账号信息或 null
   */
  getStoredUser() {
    const user = JSON.parse(localStorage.getItem("agent_user")) || JSON.parse(sessionStorage.getItem("agent_user"));
    return user && user.username && user.password ? user : null;
  },

  /**
   * 保存账号信息到存储中
   * @param {Object} user 包含用户名和密码的对象
   * @param {Boolean} remember 是否记住账号
   */
  saveUser(user, remember) {
    const storage = remember ? localStorage : sessionStorage;
    storage.setItem("agent_user", JSON.stringify(user));
  },

  /**
   * 清除存储中的用户信息
   */
  clearUser() {
    localStorage.removeItem("agent_user");
    sessionStorage.removeItem("agent_user");
  },

  /**
   * 自动跳转逻辑
   * @param {Object} router Vue Router 实例
   */
  autoRedirect(router) {
    const user = this.getStoredUser();
    if (user) {
      router.push({ path: "/home/tiepai-management" }); // 跳转到贴牌管理页面
    }
  },
};
