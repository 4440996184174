<template>
  <div class="user-profile">
    <el-card class="mb-4" shadow="never">
      
      <el-form :model="agentInfo" label-width="120px" class="agent-form" v-loading="loading">
        <el-row :gutter="20">
          <!-- 代理昵称 -->
          <el-col :span="12">
            <el-form-item label="代理昵称">
              <el-input v-model="agentInfo.agent_name" readonly />
            </el-form-item>
          </el-col>

          <!-- 上级代理 -->
          <el-col :span="12">
            <el-form-item label="上级代理">
              <el-input v-model="agentInfo.agent_pid" readonly />
            </el-form-item>
          </el-col>

          <!-- 代理等级 -->
          <el-col :span="12">
            <el-form-item label="代理等级">
              <el-input v-model="agentLevel" readonly />
            </el-form-item>
          </el-col>

          <!-- 代理数量 -->
          <el-col :span="12">
            <el-form-item label="代理数量">
              <el-input v-model="agentInfo.agent_count" readonly />
            </el-form-item>
          </el-col>

          <!-- 商户数量 -->
          <el-col :span="12">
            <el-form-item label="商户数量">
              <el-input v-model="agentInfo.store_count" readonly />
            </el-form-item>
          </el-col>

          <!-- 代理账号 -->
          <el-col :span="12">
            <el-form-item label="代理账号">
              <el-input v-model="agentInfo.username" readonly />
            </el-form-item>
          </el-col>

          <!-- 云端算力 -->
          <el-col :span="12">
            <el-form-item label="云端算力">
              <el-input v-model="agentInfo.cloud_computing" readonly />
            </el-form-item>
          </el-col>

          <!-- 本地算力 -->
          <el-col :span="12">
            <el-form-item label="本地算力">
              <el-input v-model="agentInfo.local_computing" readonly />
            </el-form-item>
          </el-col>

          <!-- 算力机数 -->
          <el-col :span="12">
            <el-form-item label="算力机数">
              <el-input v-model="agentInfo.computing_power" readonly />
            </el-form-item>
          </el-col>

          <!-- 过期时间 -->
          <el-col :span="12">
            <el-form-item label="过期时间">
              <el-input v-model="formattedOutDate" readonly />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { auth } from '@/utils/auth.js';
import config from '@/config.js'; // 引入配置文件

export default {
  name: 'UserProfile',
  setup() {
    const router = useRouter();

    // 定义响应式数据对象，用于存储代理信息
    const agentInfo = reactive({
      agent_name: '',
      agent_pid: '',
      level: '',
      agent_count: '',
      store_count: '',
      username: '',
      cloud_computing: '',
      local_computing: '',
      computing_power: '',
      out_date: '',
      // password: '', // 不展示密码
    });

    // 计算属性：根据 level 映射为对应的文字
    const agentLevel = computed(() => {
      if (agentInfo.level === '1') return '一级代理';
      if (agentInfo.level === '2') return '二级代理';
      return '未知等级';
    });

    // 计算属性：格式化过期时间
    const formattedOutDate = computed(() => {
      if (!agentInfo.out_date) return '';
      const date = new Date(agentInfo.out_date);
      if (isNaN(date)) return agentInfo.out_date;
      return date.toLocaleDateString();
    });

    // 加载状态
    const loading = ref(false);

    // 方法：获取代理信息
    const fetchAgentInfo = async () => {
      loading.value = true;
      try {
        const user = auth.getStoredUser();
        if (!user) {
          ElMessage.error('未登录，请重新登录');
          router.push('/login');
          return;
        }

        const username = user.username;

        // 发送请求获取代理信息
        const response = await axios.post(
          `${config.BASE_URL}/agent/api/agent_api.php`,
          { action: 'query', username: username }
        );

        if (response.data.success) {
          const data = response.data.data;
          // 赋值到 agentInfo 对象
          agentInfo.agent_name = data.agent_name || '';
          agentInfo.agent_pid = data.agent_pid || '';
          agentInfo.level = data.level || '';
          agentInfo.agent_count = data.agent_count || '';
          agentInfo.store_count = data.store_count || '';
          agentInfo.username = data.username || '';
          agentInfo.cloud_computing = data.cloud_computing || '';
          agentInfo.local_computing = data.local_computing || '';
          agentInfo.computing_power = data.computing_power || '';
          agentInfo.out_date = data.out_date || '';
          // 不赋值 password
        } else {
          ElMessage.error(response.data.message || '查询代理信息失败');
        }
      } catch (error) {
        console.error('查询代理信息失败:', error);
        ElMessage.error('查询代理信息失败，请检查网络连接');
      } finally {
        loading.value = false;
      }
    };

    // 在组件创建时调用 fetchAgentInfo 方法
    fetchAgentInfo();

    return {
      agentInfo,
      agentLevel,
      formattedOutDate,
      loading,
    };
  },
};
</script>

<style scoped>
.user-profile {
  padding: 1px;
}

.agent-form {
  max-width: 1000px;
}

.agent-form .el-form-item {
  margin-bottom: 20px;
}
</style>
