<!-- src/views/Home.vue -->
<template>
  <el-container style="height: 100vh;">
    <!-- 侧边栏 -->
    <AppSidebar
      :isCollapsed="isCollapsed"
      :activeMenu="activeMenu"
      @toggleAside="toggleAside"
      @menuSelect="handleMenuSelect"
    />

    <!-- 主区域 -->
    <el-container direction="vertical">
      <!-- 顶部导航栏 -->
      <AppHeader :username="username" />

      <!-- 内容区 -->
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AppSidebar from '@/components/Sidebar.vue';
import AppHeader from '@/components/Header.vue';
import { auth } from '@/utils/auth.js';

export default {
  name: 'HomePage',
  components: {
    AppSidebar,
    AppHeader,
  },
  data() {
    return {
      username: '',
      activeMenu: '',
      isCollapsed: false,
    };
  },
  methods: {
    toggleAside() {
      this.isCollapsed = !this.isCollapsed;
    },
    handleMenuSelect(index) {
      this.activeMenu = index;
      this.$router.push({ name: index });
    },
    setActiveMenu() {
      const routeName = this.$route.name;
      if (routeName) {
        this.activeMenu = routeName;
      }
    },
  },
  watch: {
    $route() {
      this.setActiveMenu();
    },
  },
  created() {
    const user = auth.getStoredUser();
    if (user && user.username) {
      this.username = user.username;
      this.setActiveMenu();
    } else {
      this.$message.error('未登录，请重新登录');
      this.$router.push('/login');
    }
  },
};
</script>

<style scoped>
.home-container {
  height: 100vh;
}
</style>
