<template>
  <div class="login-page">
    <!-- 粒子背景 -->
    <canvas id="particle-canvas"></canvas>

    <div class="login-container">
      <!-- 左侧图片部分 -->
      <div class="left-banner">
        <img src="https://www.shuzr.com/agent/images/login-banner.png" alt="数字人商业版" class="banner-image" />
      </div>

      <!-- 右侧登录表单部分 -->
      <div class="right-form">
        <div class="form-content">
          <h2 class="form-title">数字人代理端</h2>
          <p class="form-subtitle">让您的业务更高效</p>
          <el-form :model="form" ref="loginForm" class="login-form" @submit.prevent="handleLogin">
            <el-form-item>
              <el-input v-model="form.username" placeholder="请输入账号" prefix-icon="User" size="large" />
            </el-form-item>
            <el-form-item>
              <el-input v-model="form.password" placeholder="请输入密码" prefix-icon="lock" show-password size="large" />
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="form.remember" size="large">记住账号</el-checkbox>
            </el-form-item>
            <el-button type="primary" block class="login-button" @click="handleLogin" size="large">登录</el-button>
          </el-form>
          <p class="form-footer">
            忘记密码？<a href="#">点击这里</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { auth } from "@/utils/auth.js"; // 引入封装的 auth.js
import config from '@/config.js'; // 引入配置文件

export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        username: '',
        password: '',
        remember: false,
      },
    };
  },
  methods: {
    async handleLogin() {
      if (!this.form.username || this.form.username.length < 6) {
        this.$message.error("请输入至少6位的账号");
        return;
      }
      if (!this.form.password || this.form.password.length < 6) {
        this.$message.error("请输入至少6位的密码");
        return;
      }

      try {
        const response = await axios.post(
          `${config.BASE_URL}/agent/api/login.php`,
          {
            username: this.form.username,
            password: this.form.password,
          }
        );

        if (response.data.success) {
          this.$message.success("登录成功");

          // 保存账号密码到本地存储或会话存储
          auth.saveUser({
            username: this.form.username,
            password: this.form.password,
          }, this.form.remember);

          this.$router.push({ path: "/home/merchant-management" }); // 跳转到贴牌管理页面
        } else {
          this.$message.error(response.data.error || "登录失败");
        }
      } catch (error) {
        console.error("登录请求错误:", error);
        this.$message.error("登录失败，请检查网络或稍后重试");
      }
    },

    initParticles() {
      const canvas = document.getElementById('particle-canvas');
      const ctx = canvas.getContext('2d');

      const particles = [];
      const particleCount = 100;

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      window.addEventListener('resize', () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      });

      class Particle {
        constructor() {
          this.x = Math.random() * canvas.width;
          this.y = Math.random() * canvas.height;
          this.radius = Math.random() * 3 + 1;
          this.color = 'rgba(255, 255, 255, 0.8)';
          this.vx = Math.random() * 2 - 1;
          this.vy = Math.random() * 2 - 1;
        }

        draw() {
          ctx.beginPath();
          ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
          ctx.fillStyle = this.color;
          ctx.fill();
        }

        update() {
          this.x += this.vx;
          this.y += this.vy;

          if (this.x < 0 || this.x > canvas.width) this.vx *= -1;
          if (this.y < 0 || this.y > canvas.height) this.vy *= -1;
        }
      }

      function init() {
        for (let i = 0; i < particleCount; i++) {
          particles.push(new Particle());
        }
      }

      function animate() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        particles.forEach((particle) => {
          particle.update();
          particle.draw();
        });
        requestAnimationFrame(animate);
      }

      init();
      animate();
    },
  },
  mounted() {
    this.initParticles();
    auth.autoRedirect(this.$router); // 自动跳转到主页
  },
  created() {
    const savedUser = auth.getStoredUser();
    if (savedUser) {
      this.form.username = savedUser.username;
      this.form.remember = true; // 记住账号
    }
  },
};
</script>

<style scoped>
/* 页面背景保持不变，复制自原有 Login.vue */
.login-page {
  height: 100vh;
  background: linear-gradient(120deg, #7a88ff, #162447, #1e1e2f, #7a88ff);
  background-size: 400% 400%;
  animation: gradientMove 6s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* 页面背景 */
.login-page {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 粒子背景 */
#particle-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none; /* 禁止交互 */
}

/* 登录容器 */
.login-container {
  position: relative;
  z-index: 2; /* 确保在粒子背景之上 */
  display: flex;
  width: 80%;
  max-width: 900px;
  height: 450px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.1);
}

/* 左侧图片部分 */
.left-banner {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 右侧表单部分 */
.right-form {
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-content {
  width: 100%;
  max-width: 350px;
}

.form-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.form-subtitle {
  font-size: 14px;
  color: #888;
  text-align: center;
  margin-bottom: 20px;
}

.login-form .el-input {
  margin-bottom: 20px;
}

.login-button {
  margin-top: 5px;
  border-radius: 8px;
  width: 100%;
}

.form-footer {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.form-footer a {
  color: #409eff;
  text-decoration: none;
}

.form-footer a:hover {
  text-decoration: underline;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    height: auto;
    border-radius: 12px;
  }

  .left-banner {
    height: 200px;
  }

  .right-form {
    padding: 20px;
  }
}
</style>
